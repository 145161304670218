<template>
  <div class="container">
      <h1>Login Process</h1>
  </div>
</template>

<script>
export default {
  name: 'LoginProcess',
  props: {

  },
  data(){
      return {
   
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
